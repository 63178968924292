/* src/components/Login.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffe5b4; /* Couleur de fond orangée */
    text-align: center;
  }
  
  .logo {
    width: 200px; /* Double la taille de la largeur */
    height: 200px; /* Double la taille de la hauteur */
    margin-bottom: 1rem;
  }
  
  .welcome-message {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #ff8c00; /* Couleur de texte orangée */
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    color: #333;
    text-align: left;
  }
  
  .form-input {
    width: calc(100% - 20px); /* Réduire la largeur pour ajouter de l'espace pour la marge */
    padding: 0.75rem;
    font-size: 1.2rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 20px; /* Ajouter la marge à droite */
  }
  
  .error-message {
    color: red;
    margin-bottom: 1rem;
  }
  
  .login-button,
  .test-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: #ff8c00; /* Couleur de bouton orangée */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .login-button:disabled,
  .test-button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  
  .login-button:hover,
  .test-button:hover {
    background-color: #e67e00;
  }
  
  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #ff8c00; /* Orange */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
/* Center the grid container on the screen */
.grid-container {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Photo grid layout with fixed cell size */
.photo-grid {
    display: grid;
    grid-template-columns: repeat(3, auto); /* 3 columns with auto width */
    grid-auto-rows: auto; /* Auto rows based on content */
    gap: 0; /* Remove gap between cells */
    margin: 0 auto;
}

/* Individual grid cell with a slight border and fixed size */
.grid-cell {
    width: 20vh; /* Fixed width for cells */
    height: 20vh; /* Fixed height for cells */
    border: 1px solid #ccc; /* Slight border between cells */
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Ensures border doesn't add to the size */
}

/* Grid image styling */
.grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Placeholder for image upload with camera icon */
.upload-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Camera icon styling */
.camera-icon {
    font-size: 2rem;
    color: #888;
    opacity: 0.7;
}

/* Modal background styling */
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal content */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    font-family: Arial, sans-serif;
}

/* Modal image section */
.modal-image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensures the container takes full width */
    height: auto; /* Adjusts height based on content */
}

/* Modal image styling */
.modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 8px;
}
.modal-textarea-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensures the container takes full width */
    height: auto; /* Adjusts height based on content */
}

/* Modal textarea styling */
.modal-textarea {
    width: 80%; /* Adjust width as needed */
    height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    color: #333;
    resize: none;
    display: flex; /* Allows centering within the container */
    justify-content: center;
    align-items: center;
}

/* Modal textarea styling */
.modal-textarea {
    width: 80%; /* Adjust width as needed */
    height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    color: #333;
    resize: none;
    display: flex; /* Allows centering within the container */
    justify-content: center;
    align-items: center;
}

/* Modal buttons container */
.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

/* Modal button styling */
.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    color: white;
}

/* Disabled Save button styling */
.modal-button:disabled {
    background-color: #ccc; /* Greyed out background */
    cursor: not-allowed;    /* Change cursor to indicate the button is disabled */
}

/* Close button styling */
.close-button {
    background-color: #f44336;
}

/* Save button styling */
.save-button {
    background-color: #e67e00;
}
/* Date picker styling */
.date-picker {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.modal-date-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    color: #333;
    margin-top: 5px;
}

/* src/components/MainScreen.css */
.main-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #F3EFF5; /* Fond léger */
}

.sidebar {
    width: 100%;
    background-color: #454955; /* Couleur de fond orangée */
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.sidebar h2 {
  margin-bottom: 20px;
  color: #ff8c00; /* Couleur de texte orangée */
}

.search-input {
  margin-bottom: 20px;
  padding: 10px;
  width: 80%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 80%;
}

.sidebar ul li {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}

.sidebar ul li:hover {
  background-color: #f1f1f1;
}

.sidebar ul li.selected {
  background-color: #ff8c00; /* Couleur différente pour le client sélectionné */
  color: #fff; /* Couleur de texte pour le client sélectionné */
}

.content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #F3EFF5;
}
/* Ensure buttons are displayed vertically and have the same width */
.button-group {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Optional: Adjust vertical spacing between buttons */
}

.action-button {
    width: 100%; /* Buttons take full width of the parent */
    padding: 10px 20px;
    background-color: #ff8c00; /* Or any other background color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    text-align: center;
}

    /* Optional: Add a hover effect */
    .action-button:hover {
        background-color: #e67e00; /* Darken the color on hover */
    }

.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  width: 100%;
}

.upload-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
}

.upload-button {
  width: 80%;
  height: auto;
  aspect-ratio: 3/4;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upload-button i {
  font-size: 4rem;
}

.upload-button:hover {
  background-color: #e67e00;
  transform: scale(0.99);
}

.uploaded-image {
  width: 100%;
  max-width: 432px; /* Les mêmes dimensions que le bouton */
  height: auto;
  aspect-ratio: 3/4;
  border-radius: 10px;
  object-fit: cover; /* Couvrir toute la zone sans déformer l'image */
  opacity: 1;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.uploaded-image.fade-out {
  opacity: 0;
  transform: scale(0.9);
}

.caption-date-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 20px;
}

.caption-input,
.date-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-top: 10px;
}

.validate-button-container {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    align-items: center; /* Centers the button vertically */
    height: 100%; /* Optional: set height based on your layout */
}

/* Validate button styling */
.validate-button {
    height: 100%;
    width: 100%;
    background-color: #ff8c00; /* Couleur orangée */
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1rem;
    margin-top: 10px; /* Optional, depending on how much space you want above the button */
}

.validate-button:hover {
  background-color: #e67e00;
}

@media (min-width: 768px) {
  .main-screen {
    flex-direction: row;
  }

  .sidebar {
    width: 15%;
    padding: 20px;
  }

  .content {
    width: 75%;
    padding: 20px;
  }

  .caption-date-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .caption-input,
  .date-input {
    margin-top: 0;
    margin-left: 10px;
  }

  .date-input {
    flex: 1;
  }

  .caption-input {
    flex: 2;
  }
}
